import * as Immutable from 'immutable';
import StoreInterface, { MainData } from '../store-interface';
import * as AuthData from './auth-data';
import { setInitialQueryParams } from './routing';
import Redux from 'redux-thunk';
import ReactRouter from 'react-router';
import Text from '../../shared/Text';
import {
  FORGOT_PASSWORD_INDEX,
  LOGIN_INDEX,
  RESET_PASSWORD_INDEX,
  RESET_TOTP_INDEX,
} from '../constants/routes';
import { push } from 'react-router-redux';

/////////////////////
//  Action Types   //
/////////////////////

// visibleForTesting
export const IS_VALIDATING_LOGIN_TOKEN = 'login/main-data/IS_VALIDATING_LOGIN_TOKEN';
export const SET_MAIN_INITIALIZED = 'login/main-data/SET_MAIN_INITIALIZED';

/////////////////////
//     Reducer     //
/////////////////////
// this reducer is needed to expose the location data when ImmutableJS is used.
export const initialState = Immutable.fromJS({
  isValidatingToken: false,
  isInitialized: false,
} as MainData);

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MAIN_INITIALIZED:
      return state.merge({
        isInitialized: true,
      });
    case IS_VALIDATING_LOGIN_TOKEN:
      return state.merge({
        isValidatingToken: Boolean(action.payload),
      });
    default:
      return state;
  }
};

/////////////////////
// Action Creators //
/////////////////////

export function setInitialState(
  pathname: string,
  query: ReactRouter.Query,
): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch) => {
    const { logout, tt } = query as any;

    // If response_type parameter is present, it is an OAuth login, so go to LOGIN_INDEX
    const responseType = internals.getUrlSearchParam('response_type');
    if (responseType) {
      dispatch(push(LOGIN_INDEX as any));
    }
    return dispatch(AuthData.internals.getServerEnv())
      .then(() => {
        if (logout != null) {
          dispatch(AuthData.deauthenticate());
        } else {
          /**
           * Check to see if user is already authenticated and redirect them to the app that requested
           * authentication if the user is authenticated. Continue to load Login app if the user is
           * not authenticated.
           */
          const loginToken = tt || internals.getUrlSearchParam('tt');

          if (loginToken) {
            return dispatch(AuthData.authenticate({ loginToken }))
              .then(() => mainInitialized(true, query)(dispatch))
              .catch(() => {
                mainInitialized(false, query)(dispatch);
                toggleWorkingScreen(false)(dispatch)
              });
          }

          AuthData.c42Jwt
            .verifyAuthenticated()
            .then(() => {
              /**
               * if User is authenticated Login app should not continue to load.
               * Login app should redirect user to application that requested authentication.
               */
              return mainInitialized(true, query)(dispatch);
            })
            .catch(() => {
              /**
               * The user is not authenticated and the login app should continue to load. This is normal
               * when loading the login app because the user has not authenticated yet. This will reset
               * the auth state by the auth token.
               */
              mainInitialized(false, query)(dispatch);
              return AuthData.c42Jwt.logout();
            });
        }
        // tslint:disable-next-line align
        return Promise.resolve();
      })
      .catch(() => {
        // authenticate failed. Go to login unless we are resetting a password or on the forgotten password page
        if ([RESET_PASSWORD_INDEX, FORGOT_PASSWORD_INDEX, RESET_TOTP_INDEX].indexOf(pathname) === -1) {
          dispatch(push(LOGIN_INDEX as any));
        }
        return Promise.resolve();
      })
      .then(() => toggleWorkingScreen(false)(dispatch));
  };
}

export function mainInitialized(alreadyLoggedIn: boolean, query: ReactRouter.Query): Redux.ThunkAction<Promise<any>, StoreInterface, void> {

  if (alreadyLoggedIn) {
    // prevent main component from rendering the form, as we will be soon doing other stuff
    toggleWorkingScreen(true);

    return (dispatch) => {
      dispatch(setInitialQueryParams(query));
      dispatch(AuthData.onSubmitLoginSuccess());
    }
  } else {
    // we are initialized, show the form
    return (dispatch) => {
      dispatch(setInitialQueryParams(query));
      dispatch({ type: SET_MAIN_INITIALIZED });
    }
  }
}

export function initializeApplication(
  pathname: string,
  query: ReactRouter.Query,
): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch) => {
    return dispatch(internals.setInitialState(pathname, query))
      .then(() => {
        Text.initialize({});
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .then(() => toggleWorkingScreen(false)(dispatch));
  };
}

export function getUrlSearchParam(name: string): string {
  const searchProps = window.location.search
    .substr(1)
    .split('&')
    .reduce((params, item) => {
      const p = item.split('=');
      params[p[0]] = p[1];
      return params;
    }, {});
  return searchProps[name];
}

export function toggleWorkingScreen(show: boolean): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch) => dispatch({ type: IS_VALIDATING_LOGIN_TOKEN, payload: !!show });
}

export const internals = {
  setInitialState,
  getUrlSearchParam,
};
